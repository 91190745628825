<template>
  <div class="content">

    <Top />
    <Back />
    <Model />
    <RightNav  />
    <!-- 大图预览 -->
    <!-- <el-image-viewer v-if="viewer && viewer.list" :url-list="viewer.list" :on-close="close" infinite
      :initial-index="viewer.key"></el-image-viewer> -->

  </div>
</template>

<script>
import {
  Model,
  Back,
  Top
} from "./components";

import { RightNav } from "@/components";
import { } from "@/api";
import { Throttle, Debounce } from "@/utils";
export default {
  name: "Layout",
  components: {
    Model,
    Back,
    RightNav,
    Top,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {


    };

  },
  watch: {

  },
  computed: {

  },

  created() {

  },
  methods: {

  },
  async mounted() {

  },
  beforeUnmount() { },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}


</style>

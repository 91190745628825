import store from "@/store";
// 定义一个深拷贝函数  接收目标target参数
export function deepClone(target) {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值    
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}
// 根据经纬度获取高度
/**
 * @func  isEmpty
 * @desc  判断是否为空
 * @param {object} obj 需要判断的对象
 * @return {boolean} 是否为空
 */
export function isEmpty(obj) {
  if (
    typeof obj === "undefined" ||
    obj === undefined ||
    obj == null ||
    obj === ""
  ) {
    return true;
  }
  return false;
}
/**
 * @func  isFunction
 * @desc  判断是否为方法
 * @param {object} it 判断的对象
 * @return {boolean} 是否
 */
export function isFunction(it) {
  return Object.prototype.toString.call(it) === "[object Function]";
}
/**
 * @func  isArray
 * @desc  判断是否为数组
 * @param {object} it 判断的对象
 * @return {boolean} 是否
 */
export function isArray( /* anything */ it) {
  if (typeof it !== "object") {
    return false;
  }
  if (!(it instanceof Array)) {
    return false;
  }
  if (it.length === 0) {
    return false;
  }

  return true;
}

/**
 * @func  clone
 * @desc  对象复制
 * @param {object} o 复制的对象
 * @return {object} 复制的对象
 */
export function clone( /* anything */ o) {
  if (!o || typeof o !== "object" || this.isFunction(o)) {
    return o; // anything
  }
  if (o.nodeType && "cloneNode" in o) {
    return o.cloneNode(true);
  }
  if (o instanceof Date) {
    // Date
    return new Date(o.getTime());
  }
  let r, i, l, s, name;
  if (this.isArray(o)) {
    // array
    r = [];
    for (i = 0, l = o.length; i < l; ++i) {
      if (i in o) {
        r.push(this.clone(o[i]));
      }
    }
  } else {
    r = o.constructor ? new o.constructor() : {};
  }
  let empty = {};
  for (name in o) {
    s = o[name];
    if (
      !(name in r) ||
      (r[name] !== s && (!(name in empty) || empty[name] !== s))
    ) {
      r[name] = this.clone(s);
    }
  }

  return r; // Object
}

// 时间转换
export function formatTimes(startTime, endTime) {
  let diff = endTime - startTime;
  let str = "";
  // 毫秒化天
  let d = Math.floor(diff / (24 * 3600 * 1000));
  if (d > 0) {
    str += d + "天";
  }
  // 毫秒化小时
  let rh = diff % (24 * 3600 * 1000);
  let h = Math.floor(rh / (3600 * 1000));
  h = h < 10 ? "0" + h : h;
  if (h > 0 || d > 0) {
    str += h + ":";
  } else {
    str += "00:";
  }
  // 毫秒化分钟
  let rm = rh % (3600 * 1000);
  let m = Math.floor(rm / (60 * 1000));
  m = m < 10 ? "0" + m : m;
  if (m > 0 || d > 0 || h > 0) {
    str += m + ":";
  } else {
    str += "00:";
  }

  // 毫秒化秒
  let rs = rm % (60 * 1000);
  let s = Math.round(rs / 1000);
  s = s < 10 ? "0" + s : s;
  if (s > 0 || d > 0 || h > 0 || m > 0) {
    str += s;
  } else {
    str += "00";
  }
  return str;
}

export function setCookie(key, value, t) {
  var oDate = new Date(); //创建日期对象
  oDate.setDate(oDate.getDate() + t); //设置过期时间
  document.cookie = key + "=" + value + ";expires=" + oDate.toGMTString(); //设置cookie的名称，数值，过期时间
}

export function getCookie(key) {
  var arr1 = document.cookie.split("; "); //将cookie按“; ”分割，数组元素为： cookie名=cookie值
  for (var i = 0; i < arr1.length; i++) {
    //分割数组里的每个元素
    var arr2 = arr1[i].split("="); //按照“=”分割
    if (arr2[0] == key) {
      //如果数组的第一个元素等于给定的cookie名称
      return decodeURI(arr2[1]); //返回翻译编码后的cookie值
    }
  }
}

// elementUI 时间快捷选项
export function elDateTime() {
  var arr = [{
      text: "昨天6时到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        const start2 = new Date(`${y}-${m}-${r} 06:00`);
        return [start2, end];
      })(),
    },
    {
      text: "当前整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = end.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前1小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 1);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前2小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          end.getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 2);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前3小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          end.getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 3);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "最近6小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 6);
        return [start, end];
      })(),
    },
    {
      text: "最近12小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 12);
        return [start, end];
      })(),
    },
    {
      text: "最近24小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        return [start, end];
      })(),
    },
    {
      text: "最近48小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 48);
        return [start, end];
      })(),
    },
    {
      text: "最近一周",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      })(),
    },
    {
      text: "最近一个月",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      })(),
    },
    {
      text: "最近三个月",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      })(),
    },
  ];
  const time = new Date();
  if (time.getHours() >= 6) {
    arr.unshift({
      text: "今天6时到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        const start2 = new Date(`${y}-${m}-${r} 06:00`);
        return [start2, end];
      })(),
    });
  }
  return arr;
}
//wgs84_to_gcj02.js文件
//地标 转 国测 常量
var x_PI = (3.14159265358979324 * 3000.0) / 180.0;
// console.log(x_PI);
var PI = 3.1415926535897932384626;
var a = 6378245.0; //卫星椭球坐标投影到平面地图坐标系的投影因子。
var ee = 0.00669342162296594323; //椭球的偏心率。

//判断是否在国内，在中国国内的经纬度才需要做偏移
function out_of_china(lng, lat) {
  return (
    lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false
  );
}

//转化经度
function transformlng(lng, lat) {
  var ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * PI) +
        300.0 * Math.sin((lng / 30.0) * PI)) *
      2.0) /
    3.0;
  return ret;
}

//转化纬度
function transformlat(lng, lat) {
  var ret = -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}

//wgs84 to gcj02   地球坐标系 转 火星坐标系
function wgs84_to_gcj02(lng, lat) {
  lng = Number(lng);
  lat = Number(lat);
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;

    return [mglng, mglat];
  }
}

//gcj02 to wgs84  火星坐标系 转 地球坐标系
function gcj02_to_wgs84(lng, lat) {
  lng = Number(lng);
  lat = Number(lat);
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    return [lng * 2 - mglng, lat * 2 - mglat];
  }
}
//  数组总和
export function sumArr(arr) {
  let sum = 0;
  arr.forEach(e => {
    sum += Number(e)
  })
  return sum;
}
// 数组总和数组
export function getSumArr(arr) {
  let sum = 0;
  arr.forEach(e => {
    sum += Number(e)
  })
  let arr1 = []
  arr.forEach(e => {
    arr1.push(sum)
  })
  return arr1;
}
// 数组最大值
export function bigNum(arr) {
  let big = Number(arr[0]);
  arr.forEach(e => {
    big = big > Number(e) ? big : Number(e);
  })
  return big;
}

// 数组最大值组成的数组
export function bigArr(arr) {
  let big = Number(arr[0]);
  let arr1 = []
  arr.forEach(e => {
    big = big > Number(e) ? big : Number(e);
  })
  arr.forEach(e => {
    arr1.push(big)
  })
  return arr1;
}
// 获取服务器时间
function getServerTime() {
  var xhr = null;
  if (window.XMLHttpRequest) {
    xhr = new window.XMLHttpRequest();
  } else { // ie
    xhr = new ActiveObject("Microsoft")
  }

  xhr.open("GET", "/", false) //false不可变
  xhr.send(null);
  var date = xhr.getResponseHeader("Date");
  return new Date(date);
}


/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  // console.log(fn)
  // console.log(typeof fn)
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  }
};

/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
export const Throttle = (fn, t) => {
  let last;
  let timer;
  let interval = t || 500;
  return function () {
    let args = arguments;
    let now = new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  }
};


function getCount(arr) {
  var list = [];
  var num = 0;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] instanceof Array) {
      for (var j = 0; j < arr[i].length; j++) {
        list.push(arr[i][j]);
      }
    }
  }
  if (list.length) {
    num = 1
    num += getCount(list)
  }
  return num;
}


export const functions = {
  isEmpty,
  isFunction,
  isArray,
  clone,
  formatTimes,
  elDateTime,
  setCookie,
  getCookie,
  deepClone,
  wgs84_to_gcj02,
  gcj02_to_wgs84,
  sumArr,
  getSumArr,
  bigNum,
  bigArr,
  getServerTime,
};
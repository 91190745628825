import {
  get,
  post
} from "./config";


// const url1 = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : "http://192.168.110.35:7999/api/";
// const url2 = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : "http://192.168.110.44:7999/api/";
// const url3 = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : "http://192.168.110.53:7999/api/";
// const url4 = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : "http://192.168.110.38:7999/api/";


// const url = "http://192.168.110.47:8999/api/";

const url = "https://zhcr.3dy.me/api/"

export const getDataList = (data) => get(`${url}generalize/generalizeinfo/getDataList`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const getDataDetail = (data) => get(`${url}generalize/generalizeinfo/detail`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});


export const allVr = (data) => get(`${url}blade-desk/vr/allVr`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const bglist = (data) => get(`${url}spot/newsdynamic/bglist`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const allList = (data) => get(`${url}spot/newsdynamic/allList`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});


export const getNewsType = (data) => get(`${url}blade-system/dict-biz/dictionary?code=news_type`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const getNewDetail = (data) => get(`${url}spot/newsdynamic/detail`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});



// export const getNextDetail = (data) => get(`${url}generalize/generalizeinfo/getNextDetail`, data, {
//   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// });

export const getWeather = (data) => get(`${url}blade-desk/weather/data`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const getNextDetail = (data) => get(`${url}generalize/generalizeinfo/getNextDetail`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const getLastNotice = (data) => get(`${url}spot/scenicnotice/getLastNotice`, data, {
  'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});





// ------------视频---------------


// ------------------------------------------------------------------------------------------------
<template>
    <div class="backGround">
        <img src="@/assets/img/juanzhou.png" alt="">
    </div>
</template>
  
<script>


import {

} from "@/api";

// VUE
import {
    ref,
    reactive,
    computed,
    watch,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    onActivated,
    onDeactivated,
} from "vue";

export default {
    components: {

    },
    setup(props, context) {
        // 
        const _this = getCurrentInstance().proxy;
        let state = reactive({

        }); // 数据

        onActivated(() => {

        });
        onMounted(async () => {

        });
        onDeactivated(() => {

        });


        return {
            // 变量
            state,
        };
    },
};
</script>
  
<style lang="scss" scoped>
.backGround {
    width: 100vw;
    height: 100vh;
    // background-image: url('../../assets/img/juanzhou.png');
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
    pointer-events: none;

    img{
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
}
</style>
  
<template>
  <section>
    <transition :name="transitionName">
      <router-view v-if="initShow" v-slot="{ Component }">
        <transition>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </transition>
  </section>
</template>
  
<script>
export default {
  name: "Model",
  computed: {
    key() {
      return this.$route.path;
    },
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      initShow: true,
      isRouterAlive: true,
      transitionName: 'van-fade'
    };
  },
  watch: {
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      this.initShow = false
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "van-fade";
      } else {
        this.transitionName = "van-fade";
      }
      // 
      setTimeout(() => {
        this.initShow = true
      }, 100);
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      setTimeout(() => {
        this.isRouterAlive = true;
      }, 300);
    },
  },
};
</script>
  
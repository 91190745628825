<template>
  <div class=" swiper leadershipCare_asd">
    <div class="swiper-wrapper detailSwiper" v-if="type == '列表'">
      <div class="swiper-slide " v-for="(val, key) in images" :key="key + 'Swiper'">
        <img :src="(val.indexOf('http') != -1 ? val : val)" @click="tou(images, key)" />
      </div>
    </div>
    <div class="swiper-wrapper" v-else-if="type == '通知'">
      <div class="swiper-slide" v-for="(val, key) in images" :key="key + 'Swiper'">
        <div>
          <div class="actionName">
            {{
              val.title || '--'
            }}
          </div>
          <div class="actionContent">
            {{ val.contentDelTag || '--' }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-wrapper  partyBB" v-else-if="type == '党建'">
      <div class="swiper-slide" v-for="(val, key) in images" :key="key + 'Swiper'">
        <div :style="nowTitle == val.dictValue ? 'color: rgba(94, 55, 8, 1);' : 'color: rgba(94, 55, 8, 0.8);'"
          class="btnBox divFlex alignCenter flexCenter" @click="chooseThisTitle(val)">
          <img class="img"
            :src="nowTitle == val.dictValue ? require('@/assets/img/327697.png') : require('@/assets/img/327696.png')"
            alt="">
          <div class="partyName">
            {{
              val.dictValue || '--'
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-wrapper  warnContaier" v-else-if="type == '预警'">
      <div class="swiper-slide" v-for="(val, key) in images" :key="key + 'Swiper'">
        <div class="divFlex alignCenter">
          <div class="warnContent">
            {{
              val.title
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination"></div> -->

    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>

<script>
// Swiper

import Swiper from "swiper";
import 'swiper/css/swiper.css'
export default {
  name: "SwiperImage",
  // components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
    type: {
      type: String,
      default: "列表",
    },
    slidesPerView: {
      type: String,
      default: "auto",
    },
    auto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiper: false,
      nowTitle: '全部'
    };
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(1212122121213213)
      setTimeout(() => {
        var swiper = new Swiper('.leadershipCare_asd', {
          // loop: true,
          // spaceBetween: 30,
          slidesPerView: this.slidesPerView,
          // freeMode: true,
          autoplay: this.auto,
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            //点击小球的时候也切换图片
            // clickable: true,
          },
          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }, 1000);

      // console.log(this.swiper)
    });

  },
  methods: {
    chooseThisTitle(val) {
      console.log(val)
      this.nowTitle = val.dictValue;
      this.$emit('chooseThisTitle', val)
    },
    tou(images, key) {
      this.$store.commit('set', {
        key: 'viewer', val: {
          list: images, key: key,
        },
      });
    },
    mouseenterAuto(e) {
      console.log(e)
      console.log(this.swiper)
      this.swiper.forEach(element => {
        element.autoplay.start()
      });
    },
    mouseleaveAuto() {
      this.swiper.autoplay.stop();
    },
    onSwiper(swiper) {
      console.log('onSwiperonSwiper')
      this.swiper = swiper;
      this.swiper.autoplay.stop();
    },
    onSlideChange(e) {
      console.log(e)
      var player = document.getElementById("player01");
      if (player) {
        player.paused();
      }

    },
    onOpen(url) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        // techOrder: ["flash"],
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: url,
          },
        ],
        //你的封面地址
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.actionName {
  width: 80%;
  font-size: 0.4rem;
  // font-family: PingFang SC;
  font-weight: bold;
  color: #685749;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 0.6rem;

}

.btnBox {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 0.44rem;
  font-family: SourceHanSerifCNHeavy;
  font-weight: bold;
  color: rgba(94, 55, 8, 0.8);

  .img {
    width: 2.2rem;
    height: fit-content;
    position: absolute;
    object-fit: contain;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.actionContent {
  font-size: 0.36rem;
  // font-family: Source Han Sans SC;
  font-weight: 500;
  color: #857460;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 0.6rem;
}

.moren-swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.swiper-slide {
  width: 100%;
  height: 100%;

  img {
    width: 100% !important;
  }
}

.partyBB {
  .swiper-slide {
    width: 2.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }
}

.warnContaier {
  .swiper-slide {
    flex-grow: 1;
    height: 100%;
    margin-right: 0.2rem;

    .warnContent {
      font-size: 0.34rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #A57236;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.detailSwiper {
  .swiper-slide {
    height: 3.6rem;
    border-radius: 0.1rem;
    margin-right: 0.3rem;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 0.2rem;
  height: 0.2rem;
  z-index: 9;
  background: url("../assets/images/箭头.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  opacity: 0.7;
}

.swiper-button-prev {
  left: 0.2rem;
  transform: translate(0, -50%);
}

.swiper-button-next {
  right: 0.2rem;
  transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
}
</style>

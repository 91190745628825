<template>
    <div class="rightNav column alignCenter">
        <img style="width:1.68rem;height: 4.84rem;" src="@/assets/img/543.png" alt="">
        <img class="erweima" src="@/assets/static/erweima.png" alt="">
        <div class="bell" v-for="(bell, idx) in navs[path]" :key="idx + 'fdsgfdfhjgfjkhg'" @click="to(bell.url)">
            <img :src="path == bell.url ? require('@/assets/img/ac.png') : require('@/assets/img/dis.png')" alt="">

            <div class="textDiv">
                <span class="plan-bg2">{{ bell.name }}</span>
                <span class="plan-bg3">{{ bell.name }}</span>
            </div>

        </div>
        <!-- <div class="bell">
            <img src="@/assets/img/dis.png" alt="">
            <span class="plan-bg2">党建引领</span>
            <span class="plan-bg3">党建引领</span>
        </div> -->
        <img style="width:0.38rem;height: 2.05rem;" src="@/assets/img/1111.png" alt="">
    </div>
</template>
  
<script>
import {

} from "@/api";
import { } from "@/components";
export default {
    props: [],
    components: {

    },
    data() {
        return {
            navs: {
                '/Home': [{ url: '/Home', name: '乡村地图' }, { url: '/PartyBuilding', name: '党建引领' }, { url: '/Views', name: '全景模式' }],
                '/CountyDetail': [{ url: '', name: '返回' }],
                '/PartyBuilding': [{ url: '/Home', name: '乡村地图' }, { url: '/PartyBuilding', name: '党建引领' }, { url: '/Views', name: '全景模式' }],
                '/ActionDetail': [{ url: '', name: '返回' }],
                // '/Views': [{ url: '/Home', name: '乡村地图' }, { url: '/PartyBuilding', name: '党建引领' }, { url: '/Views', name: '全景模式' }],
                '/Views': [{ url: '/Home', name: '乡村地图' }, { url: '/PartyBuilding', name: '党建引领' }, { url: '/Views', name: '全景模式' }],
            }
        }
    },
    computed: {
        path() {
            return this.$route.path;
        }
    },
    watch: {

    },
    mounted() {
        // console.log(this.path)
        // console.log(this.navs[this.path])
    },
    methods: {
        to(url) {
            if (!url) this.$router.go(-1)
            this.$router.push(url)
        }
    },
};
</script>
<style lang="scss" scoped>
.erweima {
    width: 1.3rem;
    height: 1.3rem;
    background: #FFFFFF;
    box-shadow: 0 0.03rem 0.05rem 0 rgba(137, 73, 0, 0.2);
    border-radius: 0.1rem;
    position: absolute;
    top: 3.29rem;
}

.rightNav {
    position: fixed;
    left: 84vw;
    top: 7.8vh;
    z-index: 35;

    .bell {
        width: 1.22rem;
        height: 2.86rem;
        margin-top: -0.05rem;
        position: relative;
        display: flex;
        align-items: flex-end;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 1.22rem;
            height: 2.86rem;
        }

        .textDiv {
            position: relative;
            width: 1.22rem;
            height: 1.9rem;
            margin-bottom: 0.12rem;
        }

        .plan-bg2 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            font-size: 0.44rem;
            font-family: SourceHanSerifCNHeavy;
            font-weight: bold;
            color: #906E45;
             -webkit-text-stroke: 0.02rem #FFF2DF;
            text-stroke: 0.02rem #FFF2DF;
            line-height: 0.46rem;
            text-align: center;
        }

        .plan-bg3 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            font-size: 0.44rem;
            font-family: SourceHanSerifCNHeavy;
            font-weight: 900;
            color: #906E45;
            line-height: 0.46rem;
            text-align: center;
        }


    }
}
</style>
  
  
import axios from "axios";
import store from '@/store';
import router from '@/router';
import { Message } from 'element-ui'
import QS from "qs";
import Cookies from 'js-cookie';

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
  // let access_token = Cookies.get('access-token');
  // if (config.url.indexOf("open.ys7.com/api") == -1 && config.url.indexOf("blade-auth/oauth/bg/token") == -1 && config.url.indexOf("blade-auth/oauth/captcha") == -1 && config.url.indexOf("weather_mini") == -1) {
  //   config.headers['Authorization'] = "Basic c2FiZXI6c2FiZXJfc2VjcmV0";
  //   if (access_token) {
  //     config.headers['Blade-Auth'] = 'bearer ' + access_token;
  //   }
  // }

  return config;
}, (error) => {
  return Promise.reject(error);
});

//http response 拦截
axios.interceptors.response.use(res => {
  // console.log(res)
  //获取状态码
  const status = res.data.code || res.status;
  // const statusWhiteList = website.statusWhiteList || [];
  const message = res.data.msg  || '未知错误';
  //如果是401则跳转到登录页面
  // if (status === 401) {
  //   console.log(1111)
  // }
  // 如果请求为非200否者默认统一处理
  if (status != 200) {
     Message({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message))
  }
  return res;
}, (error) => {
  // console.log(error)
  // if (status == 401) {
  //   Message({
  //     message: message,
  //     type: "error",
  //     duration: 5 * 1000,
  //     offset: 120
  //   });
  // // store.dispatch('FedLogOut').then(() => router.push({path: '/login'}));
  // }
  // if (status != 200) {
  //   Message({
  //     message: message,
  //     type: "error",
  //     duration: 5 * 1000,
  //     offset: 120
  //   });
  // }
  return Promise.reject(new Error(error));
});


export function get(url, params = {}, headers = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,

      }, {
        headers
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err, res) => {
        reject(err.response);
      });
  });
}
export function post(url, params = {}, headers = "") {
  if (headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
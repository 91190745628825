import VueX from 'vuex'
import Vue from 'vue'
Vue.use(VueX);
//创建实例
const store = new VueX.Store({
    strict: true,
    state: {
        videoUrl: false,
        viewer:false
    },
    mutations: {
        set(state, {
            key,
            val
        }) {
            // console.log(key,val)
            state[key] = val;
        },
    }
    //this.$store.commit('chanegAge',val)使用方式
})
//仓库导出为一个模块
export default store;
<template>
    <div class=" swiper leadershipCare_asd" @swiper="onSwiper" @slideChange="onSlideChange">
        <div class="swiper-wrapper">
            <template v-if="(videos && videos.length > 0)">
                <div class="swiper-slide" v-for="(val, key) in videos" :key="key + 'Swiper'">
                    <live-player :ref="'ref' + key" :video-url="val" live="true" fluent stretch aspect="fullscreen"
                        :autoplay="(key == 0)" :id="idStr + key">
                    </live-player>
                </div>
            </template>
            <div class="swiper-slide" v-for="(val, key) in images" :key="key + 'Swiper111'">
                <img :src="val" />
            </div>
        </div>
    </div>
</template>
  
<script>
// Swiper

import Swiper from "swiper";
import 'swiper/css/swiper.css'
export default {
    name: "SwiperImage",
    // components: { Swiper, SwiperSlide },
    props: {
        images: {
            type: Array,
        },
        videos: {
            type: Array,
        },
        type: {
            type: String,
            default: "默认",
        },
        idStr: {
            type: String,
            default: "player",
        },
        perview: {
            default: "auto",
        },
    },
    data() {
        return {
            swiper: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            let _this = this
            setTimeout(() => {
                var swiper = new Swiper('.leadershipCare_asd', {
                    // loop: true,
                    // spaceBetween: 30,
                    slidesPerView: 1,
                    pagination: {
                        el: ".swiper-pagination",
                        //点击小球的时候也切换图片
                        // clickable: true,
                    },
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    on: {
                        slideChange: function (e) {
                            _this.onSlideChange(this)
                        },
                    },
                });
            }, 1000);

            // console.log(this.swiper)
        });

    },
    methods: {
        tou(images, key) {
            this.$store.commit('set', {
                key: 'viewer', val: {
                    list: images, key: key,
                },
            });
        },
        mouseenterAuto() {
            this.swiper.autoplay.start();
        },
        mouseleaveAuto() {
            this.swiper.autoplay.stop();
        },
        onSwiper(swiper) {
            // console.log(swiper, '1111111111111111111111')
            this.swiper = swiper;
            this.swiper.autoplay.stop();
        },
        onSlideChange(swiper) {
            // console.log(swiper, 'onSlideChange')
            var player1 = document.getElementById(this.idStr + swiper.previousIndex);
            var player2 = document.getElementById(this.idStr + swiper.activeIndex);
            if (player1 && player1.getAttribute("live")) {
                // console.log(11111111)
                player1.setAttribute("video-url", "");

            }
            if (player2 && player2.getAttribute("live")) {
                // console.log(222222)
                player2.setAttribute("video-url", this.videos[swiper.activeIndex]);
                player2.getVueInstance().play();
                player2.getVueInstance().setMuted(true);
            }

        },
        onOpen(url) {
            return {
                playbackRates: [0.5, 1.0, 1.5, 2.0],
                autoplay: false,
                muted: false,
                loop: false,
                preload: "auto",
                language: "zh-CN",
                aspectRatio: "16:9",
                fluid: true,
                // techOrder: ["flash"],
                sources: [
                    {
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: url,
                    },
                ],
                //你的封面地址
                poster: "",
                notSupportedMessage: "此视频暂无法播放，请稍后再试",
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true,
                },
            };
        },
    },
};
</script>
  
<style lang="scss" scoped>
.moren-swiper {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.leadershipCare_asd {
    width: 100%;
    height: 4.1rem;
    box-sizing: border-box;
    padding: 0.2rem 0;
    overflow: hidden;
    border-radius: 0.3rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 0.2rem;
    height: 0.2rem;
    z-index: 9;
    background: url("../assets/images/箭头.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    opacity: 0.7;
}

.swiper-button-prev {
    left: 0.2rem;
    transform: translate(0, -50%);
}

.swiper-button-next {
    right: 0.2rem;
    transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 1;
}

.swiper-button-disabled {
    opacity: 0.7 !important;
    cursor: not-allowed;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: "" !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: "" !important;
}
</style>
  
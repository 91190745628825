<template>
    <div class="topBox bet">
        <div v-if="weatherData" class="weatherBox divFlex alignCenter flexCenter">
            <img :src="require(`@/assets/weather/${weatherData.condtxt}.png`)" alt="">
            <span>{{ weatherData.condtxt }} {{ weatherData.temperature }} ℃</span>
        </div>
        <div class="titleName">
            <div class="border2">{{ navs[path] }}</div>
            <div class="border1">{{ navs[path] }}</div>
        </div>
        <div class="timeBox">{{ hour }}:{{ minute }}:{{ second }}</div>
    </div>
</template>
  
<script>
import {
    getWeather
} from "@/api";
import { } from "@/components";
export default {
    props: [],
    components: {

    },
    data() {
        return {
            navs: {
                '/Home': '乡村地图',
                '/CountyDetail': '乡村详情',
                '/PartyBuilding': '党建引领',
                '/ActionDetail': '动态详情',
                '/Views': '全景模式',
            },
            hour: false,
            minute: false,
            second: false,
            weatherData: false
        }
    },
    computed: {
        path() {
            return this.$route.path;
        }
    },
    watch: {

    },
    mounted() {
        this.time();
        setInterval(this.time, 1000);
        getWeather().then(res => {
            // console.log(res)
            this.weatherData = res.data
        })
    },
    methods: {
        to(url) {
            this.$router.push(url)
        },
        time() {
            var date = new Date();
            // this.year = date.getFullYear();
            // this.month = date.getMonth() + 1;
            // this.date = date.getDate();
            // this.day = new Array(
            //     "星期日",
            //     "星期一",
            //     "星期二",
            //     "星期三",
            //     "星期四",
            //     "星期五",
            //     "星期六"
            // )[date.getDay()];
            this.hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            this.minute =
                date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            this.second =
                date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        },
    },
};
</script>
<style lang="scss" scoped>
.topBox {
    width: 9.63rem;
    height: 0.8rem;
    padding: 0 0.18rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 2.8vh;
    transform: translate(-50%, 0);
    z-index: 4;

    .weatherBox {
        width: 2rem;
        height: 0.48rem;
        background: rgba(165, 114, 54, 0.4);
        border-radius: 0.24rem;
        font-size: 0.28rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;

        img {
            width: 0.29rem;
            object-fit: contain;
            margin-right: 0.05rem;
        }
    }

    .titleName {
        position: absolute;
        left: 38%;
        top: 10%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: fit-content;
        white-space: nowrap;

        .border2 {
            width: fit-content;
            height: -moz-fit-content;
            font-size: 0.64rem;
            font-family: SourceHanSerifCNHeavy;
            font-weight: bold;
            color: #553510;
            background-image: linear-gradient(to right, #553510 0%, #553510 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: absolute;
            z-index: 2;
            line-height: 0.64rem;
        }

        .border1 {
            width: fit-content;
            height: fit-content;
            font-size: 0.66rem;
            font-family: SourceHanSerifCNHeavy;
            font-weight: bold;
            color: rgba(255, 224, 169, 0.80);
            -webkit-text-stroke: rgba(255, 224, 169, 0.80);
            text-stroke: rgba(255, 224, 169, 0.80);
            left: -0.05rem;
            position: absolute;
            background: linear-gradient(0deg, rgba(255, 224, 169, 0.80) 0%, rgba(255, 224, 169, 0.80) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 0.64rem;
        }
    }

    .timeBox {
        width: 2rem;
        height: 0.5rem;
        background: rgba(165, 114, 54, 0.4);
        border-radius: 0.24rem;
        font-size: 0.3rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        line-height: 0.5rem;
    }
}
</style>
  
  
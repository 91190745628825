import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import dialogDrag from '@/utils/dialogDrag.js' // 地址就是dialogDrag.js在位置
import "@/styles/index.scss";
import rem from './assets/rem.js'
import {
    VueMasonryPlugin
} from 'vue-masonry';
import {
    functions
} from "@/utils";

import '@babel/polyfill'
import Es6Promise from 'es6-promise'

require('es6-promise').polyfill()
Es6Promise.polyfill()

Vue.use(VueMasonryPlugin);
Vue.use(dialogDrag);
Vue.use(Vant);
Vue.use(rem);
Vue.prototype.$axios = axios
Vue.prototype.$ = $;
Vue.use(ElementUI);
Vue.prototype.$ip = process.env.NODE_ENV === 'production' ? 'https://sg.3dy.me/h5/#/' : "https://192.168.110.213:8080/h5/#/";
Vue.config.productionTip = false
for (var key in functions) Vue.prototype[key] = functions[key];
new Vue({
    axios,
    router,
    store,
    ElementUI,
    VueMasonryPlugin,
    dialogDrag,
    render: h => h(App)
}).$mount('#app')
<template>
  <div class=" swiper leadershipCare_asd">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(val, key) in images" :key="key + 'Swiper'">
        <div>
          <div class="actionName">
            {{
                val.title || '--'
            }}
          </div>
          <div class="actionContent">
            {{ val.contentDelTag || '--' }}
          </div>
        </div>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination"></div> -->

    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>

</template>

<script>
// Swiper

import Swiper from "swiper";
import 'swiper/css/swiper.css'
export default {
  name: "SwiperImage",
  // components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
    type: {
      type: String,
      default: "默认",
    },
  },
  data() {
    return {
      swiper: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(1212122121213213)
      setTimeout(() => {
        var swiper = new Swiper('.leadershipCare_asd', {
          // loop: true,
          spaceBetween: 30,
          slidesPerView: 1,
          // freeMode: true,
          // autoplay: { delay: 1000, },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            //点击小球的时候也切换图片
            // clickable: true,
          },
          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }, 1000);

      // console.log(this.swiper)
    });

  },
  methods: {
    tou(images, key) {
      this.$store.commit('set', {
        key: 'viewer', val: {
          list: images, key: key,
        },
      });
    },
    mouseenterAuto(e) {
      console.log(e)
      console.log(this.swiper)
      this.swiper.forEach(element => {
        element.autoplay.start()
      });
    },
    mouseleaveAuto() {
      this.swiper.autoplay.stop();
    },
    onSwiper(swiper) {
      console.log('onSwiperonSwiper')
      this.swiper = swiper;
      this.swiper.autoplay.stop();
    },
    onSlideChange(e) {
      console.log(e)
      var player = document.getElementById("player01");
      if (player) {
        player.paused();
      }

    },
    onOpen(url) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        // techOrder: ["flash"],
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: url,
          },
        ],
        //你的封面地址
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.actionName {
  width: 80%;
  font-size: 0.4rem;
  // font-family: PingFang SC;
  // font-weight: bold;
  color: #685749;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.actionContent {
  font-size: 0.36rem;
  // font-family: Source Han Sans SC;
  font-weight: 500;
  color: #857460;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.moren-swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.leadershipCare_asd {
  width: 100%;
  height: 4.1rem;
  box-sizing: border-box;
  padding: 0.2rem 0;
  overflow: hidden;
  border-radius: 0.3rem;

  img {
    width: auto;
    height: 100%;
    border-radius: 0.3rem;
    // object-fit: contain;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 0.2rem;
  height: 0.2rem;
  z-index: 9;
  background: url("../assets/images/箭头.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  opacity: 0.7;
}

.swiper-button-prev {
  left: 0.2rem;
  transform: translate(0, -50%);
}

.swiper-button-next {
  right: 0.2rem;
  transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
}
</style>

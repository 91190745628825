(function (doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            // var clientWidth = docEl.clientWidth > 3840 ? 3840 : docEl.clientWidth;
            // console.log(11)
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) {
                return
            }
            console.log(clientWidth)
            docEl.style.fontSize = 100 * (clientWidth / 1080) + 'px';
            window.dif = 100 * (clientWidth / 1080)
        };
    recalc();

    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);

})(document, window)
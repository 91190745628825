const dialogDrag = (app) => {
  app.directive("dialogdrag", {
    // 渲染完毕
    mounted(el) {
      // 可视窗口的宽度
      const clientWidth = document.documentElement.clientWidth;
      // 可视窗口的高度
      const clientHeight = document.documentElement.clientHeight;
      // 记录坐标
      let domset = {
        x: clientWidth / 4, // 默认width 50%
        y: (clientHeight * 15) / 100, // 根据 15vh 计算
      };

      // 弹窗的容器
      const domDrag = el;
      // 重新设置上、左距离
      domDrag.style.top = domset.y + "px";
      domDrag.style.left = domset.x + "px";

      // 记录拖拽开始的光标坐标，0 表示没有拖拽
      let start = { x: 0, y: 0 };
      // 移动中记录偏移量
      let move = { x: 0, y: 0 };

      domDrag.onmousedown = (e) => {
        // 鼠标按下，开始拖拽
        document.onmousedown = (e) => {
          // 判断对话框是否重新打开
          if (domDrag.style.top === "15vh") {
            // 重新打开，设置 domset.y  top
            domset.y = (clientHeight * 15) / 100;
          }
          start.x = e.clientX;
          start.y = e.clientY;
          domDrag.style.cursor = "move"; // 改变光标形状
          window.zIndex += 1;
          domDrag.style.zIndex = window.zIndex; // 改变光标形状
        };

        // 鼠标移动，实时跟踪
        document.onmousemove = (e) => {
          if (start.x === 0) {
            // 不是拖拽状态
            return;
          }
          move.x = e.clientX - start.x;
          move.y = e.clientY - start.y;

          var x = domset.x + move.x;
          var y = domset.y + move.y;

          if (x < 0) x = 0;
          if (y < 0) y = 0;
          if (x > clientWidth - 580) x = clientWidth - 580;
          if (y > clientHeight - 330) y = clientHeight - 330;

          // 初始位置 + 拖拽距离
          domDrag.style.left = x + "px";
          domDrag.style.top = y + "px";
        };
        // 鼠标抬起，结束拖拽
        document.onmouseup = (e) => {
          move.x = e.clientX - start.x;
          move.y = e.clientY - start.y;

          // 记录新坐标，作为下次拖拽的初始位置
          domset.x += move.x;
          domset.y += move.y;

          if (domset.x < 0) domset.x = 0;
          if (domset.y < 0) domset.y = 0;
          if (domset.x > clientWidth - 1920 * 0.2)
            domset.x = clientWidth - 1920 * 0.2;
          if (domset.y > clientHeight - 1080 * 0.2)
            domset.y = clientHeight - 1080 * 0.2;
          domDrag.style.cursor = ""; // 恢复光标形状
          domDrag.style.left = domset.x + "px";
          domDrag.style.top = domset.y + "px";
          // 结束拖拽
          start.x = 0;
        };
      };
      domDrag.onmouseup = (e) => {
        document.onmousedown = () => {};
        document.onmousemove = () => {};
        document.onmouseup = () => {};
      };
    },
  });
};
export default dialogDrag;

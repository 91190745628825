import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Layout from "@/layout";
const routes = [{
    path: "/Home",
    component: Layout,
    redirect: "/Home",
    meta: {
      index: 1,
      title: "",
      requireAuth: false,
      keepAlive: true
    },
    children: [{
      meta: {
        index: 1,
      },
      path: "/Home",
      component: () => import("@/views/Index"),
      name: "主页",
    }, ],
  },
  {
    path: "/CountyDetail",
    component: Layout,
    redirect: "/CountyDetail",
    meta: {
      index: 2,
      title: "",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      meta: {
        index: 2,
      },
      path: "/CountyDetail",
      component: () => import("@/views/CountyDetail"),
      name: "乡村详情",
    }, ],
  },
  {
    path: "/PartyBuilding",
    component: Layout,
    redirect: "/PartyBuilding",
    meta: {
      index: 1,
      title: "",
      requireAuth: false,
      keepAlive: true
    },
    children: [{
      meta: {
        index: 1,
      },
      path: "/PartyBuilding",
      component: () => import("@/views/PartyBuilding"),
      name: "党建引领",
    }, ],
  },
  {
    path: "/ActionDetail",
    component: Layout,
    redirect: "/ActionDetail",
    meta: {
      index: 2,
      title: "",
      requireAuth: false,
      keepAlive: true
    },
    children: [{
      meta: {
        index: 2,
      },
      path: "/ActionDetail",
      component: () => import("@/views/ActionDetail"),
      name: "动态详情",
    }, ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/Views",
    meta: {
      index: 1,
      title: "",
      requireAuth: false,
      keepAlive: true
    },
    children: [{
      meta: {
        index: 1,
      },
      path: "/Views",
      component: () => import("@/views/Views"),
      name: "全景模式",
    }, ],
  },
]


const router = new VueRouter({
  routes
})

console.log(router)
export default router